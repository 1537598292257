import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Card, CardContent, CardHeader, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import UserServices from '../../../../../services/userServices';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const [userData, setUserData] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [activeMeetings, setActiveMeetings] = useState(0);
  const [latestUsers, setLatestUsers] = useState([]);
  const [latestCompanies, setLatestCompanies] = useState([]);
  const [meetingsToday, setMeetingsToday] = useState(0);
  const [meetingsThisWeek, setMeetingsThisWeek] = useState(0);
  const [meetingsThisMonth, setMeetingsThisMonth] = useState(0);
  const [meetingsThisYear, setMeetingsThisYear] = useState(0);
  const [topCompanies, setTopCompanies] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [companyData, setCompanyData] = useState([]); // Firma verilerini tutacak state

  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Kullanıcı verilerini çekmek
    UserServices.getAllUsers()
      .then((res) => {
        const sortedUsers = res.data.sort((a, b) => new Date(b.created) - new Date(a.created)); // En yeni oluşturulma tarihi
        setUserData(sortedUsers);
        setTotalUsers(sortedUsers.length);  // Toplam kullanıcı sayısı
        setLatestUsers([sortedUsers[0], ...sortedUsers.slice(1, 5)]);  // En yeni üyelikten başla, ilk 5 kullanıcıyı al
      })
      .catch((error) => {
        console.error('Veri alınırken hata oluştu:', error);
      });

    // Firma verilerini çekmek
    UserServices.getAllCompanies()
      .then((res) => {
        setCompanyData(res.data);
        setTotalCompanies(res.data.length);  // Toplam firma sayısını burada alıyoruz
        setLatestCompanies(res.data.slice(0, 5)); // Son 5 firmayı alıyoruz
      })
      .catch((error) => {
        console.error('Veri alınırken hata oluştu:', error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Dummy data for other stats
    setActiveMeetings(15);
    setMeetingsToday(5);
    setMeetingsThisWeek(30);
    setMeetingsThisMonth(120);
    setMeetingsThisYear(1427);
    setTopCompanies([
      { name: 'Firma A', meetings: 50 },
      { name: 'Firma B', meetings: 40 },
      { name: 'Firma C', meetings: 30 },
    ]);
    setTopUsers([
      { name: 'Ahmet Yılmaz', meetings: 10 },
      { name: 'Ayşe Kılıç', meetings: 8 },
      { name: 'Murat Demir', meetings: 6 },
    ]);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('dashboard')}
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* User and Company Statistics */}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardLiveData')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title={t('dashboardActiveMeetings')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{activeMeetings}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title={t('dashboardOnlineUsers')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{onlineUsers}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardUserAndCompanyStatistics')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title= {t('dashboardRegisteredUsers')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{totalUsers}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title={t('dashboardRegisteredCompanies')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{totalCompanies}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Meeting Statistics */}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardMeetingStatistics')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Card>
                    <CardHeader title={t('packetsDaily')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{meetingsToday}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card>
                    <CardHeader title={t('packetsWeekly')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{meetingsThisWeek}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card>
                    <CardHeader title={t('packetsMonthly')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{meetingsThisMonth}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card>
                    <CardHeader title={t('packetsYearly')} />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: 'black' }}>{meetingsThisYear}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>


          {/* Last 5 Users and Companies */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardLastUsers')}
              </Typography>
              <List>
                {latestUsers.map((user, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      secondary={user.fullName}
                      //secondary={user.email}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardLastCompanies')}
              </Typography>
              <List>
                {latestCompanies.map((company, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      secondary={company.name}
                     // secondary={company.email}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Most Active Companies and Users */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardMostUsers')}
              </Typography>
              <List>
                {topUsers.map((user, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={user.name} secondary={`Toplantı Sayısı: ${user.meetings}`} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
              <Typography variant="h6" gutterBottom>
                {t('dashboardMostCompanies')}
              </Typography>
              <List>
                {topCompanies.map((company, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={company.name} secondary={`Toplantı Sayısı: ${company.meetings}`} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>


        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
